@import "./assets/fonts/stylesheet.css";
body {
  margin: 0;
  font-family: "Manrope", "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.stylish-scroll::-webkit-scrollbar {
  width: 5px;
}

.stylish-scroll::-webkit-scrollbar-track {
  background-color: transparent !important;
  border-radius: 100px;
}

.stylish-scroll::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 5px solid transparent;
  background-clip: content-box;
  background: rgba(129, 56, 223, 0.20);
}

@tailwind base;
@tailwind components;
@tailwind utilities;
