input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

label.custom-radio-button input[type="radio"] {
  opacity: 0;
}
input[type="datetime-local"] {
  padding-bottom: 0px !important;
}
label.custom-radio-button input[type="radio"] ~ .helping-el {
  background-color: #ffffff;
  border: 1.5px solid #10b981;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
  padding: 6px;
  position: relative;
  top: 3px;
}
/* scrollbar width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.ChapterWiseMarks tbody td {
  padding: 5px;
}
/* scrollbar bg color */
::-webkit-scrollbar-track {
  background: transparent;
}

//text size css to handle with button
.customSize14 .customSizeWrapper .container input {
  font-size: 14px;
}

.customSize16 .customSizeWrapper .container input {
  font-size: 16px;
}

.customSize18 .customSizeWrapper .container input {
  font-size: 18px;
}
.customSize14 img {
  width: 12% !important;
}

.customSize16 img {
  width: 18% !important;
}

.customSize18 img {
  width: 20% !important;
}

.optionImageWrappercustomSize14 img {
  height: 48px !important;
  width: 48px !important;
}

.optionImageWrappercustomSize16 img {
  height: 60px !important;
  width: 60px !important;
}

.optionImageWrappercustomSize18 img {
  height: 80px !important;
  width: 80px !important;
}

/* scrollbar color */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* scrollbar colors on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
label.custom-radio-button input[type="radio"]:checked ~ .helping-el {
  border: 1.5px solid #10b981;
}

label.custom-radio-button input[type="radio"]:checked ~ .helping-el:after {
  background-color: #10b981;
  border-radius: 50%;
  content: " ";
  font-size: 33px;
  left: 2px;
  position: absolute;
  top: 2px;
  height: 8px;
  width: 8px;
}

.custom-checkbox {
  display: block;
}

.custom-checkbox input {
  display: none;
}

.custom-checkbox label {
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.custom-checkbox label:before {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 6px;
  cursor: pointer;
  background-image: none;
}

.custom-checkbox input:checked + label:before {
  content: "";
  background-image: url("./assets/icons/tick.svg");
  background-color: #1e1e2d;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 10px 10px;
}

.custom-checkbox.secondary input:checked + label:before {
  content: "";
  background-color: #10b981 !important;
}

// table style

tbody td {
  padding: 30px;
}

tbody tr:nth-child(odd) {
  background-color: rgb(242, 242, 243);
}

//  confirm box css override
.react-confirm-alert-overlay {
  background: rgb(17 24 39 / 50%) !important;
}

.overlay-custom-class-name {
  .react-confirm-alert {
    .react-confirm-alert-body {
      text-align: center;

      h1 {
        color: #000;
        font-size: 22px;
        padding-bottom: 26px;
      }
    }

    .react-confirm-alert-button-group {
      display: flex;
      justify-content: center;
      gap: 30px;

      button {
        border-radius: 5px;
        background: #1e1e2d;
        margin: 0 !important;
        height: 100%;
      }

      button[label="Yes"] {
        background: #1e1e2d;
      }

      .cancel-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #1e1e2d;
        background-color: transparent !important;
        color: #1e1e2d;
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;
        margin: 0;
      }

      .delete-btn {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

.profilepic .error-message {
  color: rgb(244, 67, 54);
  font-size: 12px;
}
input[type="radio"] {
  color: #10b981;

  &:hover {
    cursor: pointer;
  }
}

input[type="radio"]:checked {
  border-color: #10b981 !important;
}

input[type="radio"]:focus {
  --tw-ring-color: transparent !important;
}

:root {
  --circle-size: clamp(1rem, 30px, 2rem);
  --spacing: clamp(0.25rem, 2vw, 0.5rem);
}

.c-stepper {
  display: flex;
}

.c-stepper__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;

  &:before {
    --size: 3rem;
    content: "";
    display: block;
    width: var(--circle-size);
    height: var(--circle-size);
    border-radius: 50%;
    background-color: lightgrey;
    background-color: red;
    opacity: 0;
    margin: 0 auto 1rem;
  }

  &:not(:last-child) {
    &:after {
      content: "";
      position: relative;
      top: calc(var(--circle-size) / 2);
      width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
      left: calc(32% + calc(var(--circle-size) / 2 + var(--spacing)));
      height: 2px;
      background-color: #e0e0e0;
      order: -1;
    }
  }
}

.c-stepper__title {
  font-size: 12px;
  margin-bottom: 0.5rem;
}

.c-stepper__desc {
  color: grey;
  font-size: clamp(0.85rem, 2vw, 1rem);
  padding-left: var(--spacing);
  padding-right: var(--spacing);
}

/*** Non-demo CSS ***/

.wrapper {
  max-width: 1000px;
  margin: 2rem auto 0;
}
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
